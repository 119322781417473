import React, { useRef, useState } from 'react';
import './App.css'; // Stilleri içe aktar
import icon from './copy.png';

const ReverseKeyboardLookup = () => {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const textAreaRef = useRef(null);

  const keyboardLayout = "1234567890qwertyuiopasdfghjklzxcvbnmQ";

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim() === '') return;

    const results = input.split('\n').map(line => {
      const phrases = line.split(';').map(phrase => {
        const match = phrase.trim().match(/look between (\w+) and (\w+)/);
        if (!match) {
          return ' '; // Skip invalid formats
        }
        const [_, left, right] = match;
        const leftIndex = keyboardLayout.indexOf(left);
        const rightIndex = keyboardLayout.indexOf(right);

        if (leftIndex === -1 || rightIndex === -1 || Math.abs(leftIndex - rightIndex) !== 2) {
          return ''; // Skip if no valid character is found
        }
        return keyboardLayout[(leftIndex + rightIndex) / 2];
      });
      return phrases.join(''); // Join phrases without space for continuous text
    });

    setOutput(results.join('')); // Join results into a single line without new lines
  };


  const handleCopy = () => {
    textAreaRef.current.select();
    document.execCommand('copy');
  };

  return (
    <div className="app">
      <h1>Look Between to Text Converter</h1>
      <form onSubmit={handleSubmit} className="form-group">
        <textarea
          className="input textarea" // Added class for consistent styling
          value={input}
          onChange={handleInputChange}
          placeholder="Enter look between text"
          rows="1" // Mimics input field
          style={{ overflowY: 'hidden', resize: 'none' }} // Prevents scroll and resize
        />
        <button type="submit">Submit</button>
      </form>
      <div className="output-area">
        <div className="header">
          <span>Output</span>
          <button onClick={handleCopy} className="copy-button">
            <img src={icon} alt="Copy" />
          </button>
        </div>
        <textarea
          ref={textAreaRef}
          className="output"
          value={output}
          readOnly
          rows="10" // Keeping it as a multiline textarea for output
        />
      </div>
    </div>
  );
};

export default ReverseKeyboardLookup;
