import React from 'react';
import KeyboardLookup from './KeyboardLookup';
import ReverseKeyboardLookup from './ReverseKeyboardLookup';
import pp from './images/pp.jpeg';
import burcu from'./images/burcu.jpg';
import { Helmet } from 'react-helmet';
import ReactGA from "react-ga4";

ReactGA.initialize("G-B55WSRRCF2");
const App = () => {
  const featuredAccounts = [
    { name: "behlul", imageUrl:pp, url: "https://x.com/behlulcansahin" },
    { name: "Account 1", imageUrl:burcu, url: "https://x.com/bgnctrk" },
    

  
    // Add more accounts as needed
  ];
  
  const handlePromoteClick = () => {
    const emailAddress = 'behlulcansahin@gmail.com'; // Replace with your email address
    const subject = 'Inquiry about Promotion'; // Subject of the email
    const body = 'Hello,\n\nI am interested in getting promoted. Can you please provide more information?\n\nThank you.'; // Body of the email
    const mailtoLink = `mailto:${encodeURIComponent(emailAddress)}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };
  
  return (
    <div>
      <Helmet>
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-0413433455543433"
     crossorigin="anonymous"></script>
        <title>Text to Look Between Converter</title>
        <meta name="description" content="Convert text into 'look between X and Y' format easily." />
        <meta name="keywords" content="text converter, look between, text format, look between converter, look between letters translator, 
        look between words, look between x and y, look between x and y translator, look between x and y converter, look between letters converter,
         look between to text, text to look between, look between to text converter, look between to text translator" />
         <meta property="og:title" content="Text to Look Between Converter" />
  <meta property="og:description" content="Convert text into 'look between X and Y' format easily." />
  <meta property="og:type" content="website" />
  <meta property="og:image" content="https://previews.dropbox.com/p/thumb/ACRLsS06lWvNhHt6ThWE0AmSjtKI5e8lmUWTng_Wqr4uapeT0HflTzE9Ly0usEf3SmpbfOtSadZjgce7Ggphg3b2ZDKugw4s6dPK_zh82pEeZlVRJPcJrH55712dS9zz9kCiK3YwJQIbehmnSzl95J8G2H4ld8EFq5qBownp_UBMeRD92IHDaaROStKjF0wpiZlXcfwJXUBneztQLmsYEUGZlo52XteM3-_BMEMLE2q-Ew1jM812dTVYAYU3tXKv5aY16nEgU0OfwEWqhh8x_DHGrH71ydHiYeGdlWDJlyCYKteq8R4dQoPybED18HzZq5BNdJixzgzmJSbx9Z_X6kas/p.jpeg" />
  <meta property="og:url" content="url_to_your_app" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Text to Look Between Converter" />
  <meta name="twitter:description" content="Convert text into 'look between X and Y' format easily." />
  <meta name="twitter:image" content="https://previews.dropbox.com/p/thumb/ACRLsS06lWvNhHt6ThWE0AmSjtKI5e8lmUWTng_Wqr4uapeT0HflTzE9Ly0usEf3SmpbfOtSadZjgce7Ggphg3b2ZDKugw4s6dPK_zh82pEeZlVRJPcJrH55712dS9zz9kCiK3YwJQIbehmnSzl95J8G2H4ld8EFq5qBownp_UBMeRD92IHDaaROStKjF0wpiZlXcfwJXUBneztQLmsYEUGZlo52XteM3-_BMEMLE2q-Ew1jM812dTVYAYU3tXKv5aY16nEgU0OfwEWqhh8x_DHGrH71ydHiYeGdlWDJlyCYKteq8R4dQoPybED18HzZq5BNdJixzgzmJSbx9Z_X6kas/p.jpeg" />

        {/* Add more metadata tags as needed */}
      </Helmet>
      <KeyboardLookup />
      <ReverseKeyboardLookup />
     
      <div className="featured-accounts">
        <h2>Featured Accounts 👑</h2>
        <div className="profiles">
          {featuredAccounts.map(account => (
            <a key={account.name} href={account.url} target="_blank" rel="noopener noreferrer">
              <img src={account.imageUrl} alt={account.name} className="profile-pic" />
            </a>
          ))}
        </div>
        <button onClick={handlePromoteClick} className="get-featured-button">Get Featured 🔥</button>
      </div>
    </div>
  );
};

export default App;
