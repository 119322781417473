import React, { useRef, useState } from 'react';
import './App.css'; // Stilleri içe aktar
import icon from './copy.png';
import ReverseKeyboardLookup from './ReverseKeyboardLookup';
const KeyboardLookup = () => {
  const [input, setInput] = useState('');
  const [output, setOutput] = useState('');
  const textAreaRef = useRef(null);

  const keyboardLayout = "1234567890qwertyuiopasdfghjklzxcvbnmQ";

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim() === '') return;

    const results = input.toLowerCase().split('').map(letter => {
      if (letter === ' ') {
        return ``;
      }
      const index = keyboardLayout.indexOf(letter);
      if (index === -1) {
        return ``;
      }
      const left = index > 0 ? keyboardLayout[index - 1] : "none";
      const right = index < keyboardLayout.length - 1 ? keyboardLayout[index + 1] : "none";
      return `look between ${left} and ${right}`;
    });

    setOutput(results.join('\n'));
  };

  const handleCopy = () => {
    textAreaRef.current.select();
    document.execCommand('copy');
  };

  return (
    <div className="app">
      <h1>Text to Look Between Converter</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={input}
          onChange={handleInputChange}
          placeholder="Enter a word or letter"
        />
        <button type="submit">Submit</button>
      </form>
      <div className="output-area">
        <div className="header">
          <span>Output</span>
          <button onClick={handleCopy} className="copy-button">
            <img src={icon} alt="Copy" /> {/* copy-icon.png ikon dosyanızı uygun bir yerde saklayın */}
          </button>
        </div>
        <textarea
          ref={textAreaRef}
          className="output"
          value={output}
          readOnly
          rows="10"
        />
      </div>
      
    </div>
  );
};

export default KeyboardLookup;
